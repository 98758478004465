import React from 'react';
import { Button, Label, Textarea } from 'flowbite-react';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { User } from '../hooks/AuthProvider';
import { Unit } from '../components/UnitCard';
import Swal from 'sweetalert2';
import PageHeader from '../components/PageHeader';
import AnswerForm from '../components/AnswerForm';
import UnitHeader from '../components/UnitHeader';

interface Checklist {
    id: number,
    title: string,
    answers: Answer[],
    createdBy: User,
    unit: Unit,
    note: string,
    createdAt: string,
    question: string,
}

interface Answer {
    id: number,
    question: string,
    type: string,
    answer: any,
    note: string,
    createdAt: string,
    updatedAt: string
}
const ChecklistPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const checklistId = params.checklistId;
    const questionId = params.questionId;

    const [currentAnswer, setCurrentAnswer] = useState<Answer | null>(null);
    const [checklist, setChecklist] = useState<Checklist | null>(null);
    const [currentAnswerIndex, setCurrentAnswerIndex] = useState<number>(0);

    const totalAnswers = checklist?.answers.length || 0;

    // Fetch Checklist
    useEffect(() => {
        const fetchChecklist = async () => {
            try {
                const response = await axios.get(`/checklists/${checklistId}`);
                setChecklist(response.data);
            } catch (err) {
                console.log("Check Error", err);
            }
        };
        if (checklistId) {
            fetchChecklist();
        }
    }, [checklistId]);

    // Navigate to first answer or throw error no question exist
    useEffect(() => {
        if (!questionId) {
            const answers = checklist?.answers;
            if (answers && answers.length > 0) {
                navigate(`/checklist/${checklistId}/question/${answers[0].id}`)
            } else if (checklist) {
                Swal.fire({
                    title: 'There is no question exist in this checklist',
                    icon: 'warning'
                });
            }
        } else if (!currentAnswerIndex && questionId !== null) {
            let currentAnswerIndex = checklist?.answers.findIndex((answer, index) => {
                if (answer.id === parseInt(questionId)) {
                    return true;
                }
                return false;
            }) as number
            setCurrentAnswer(checklist?.answers[currentAnswerIndex] as Answer);
            setCurrentAnswerIndex(currentAnswerIndex)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklist, checklistId, navigate, questionId])

    // Change url on answer change
    useEffect(() => {
        if (currentAnswer) {
            navigate(`/checklist/${checklistId}/question/${currentAnswer.id}`);
        }
    }, [currentAnswer, checklistId, navigate])

    const saveAnswer = (answer: any = undefined, note: string | null = null) => {
        if (currentAnswer) {
            if (answer !== undefined) {
                currentAnswer.answer = answer;
            }
            if (note !== null) {
                currentAnswer.note = note;
            }
            setCurrentAnswer({ ...currentAnswer });
            if (checklist?.answers[currentAnswerIndex]) {
                checklist.answers[currentAnswerIndex] = currentAnswer
            }
            axios.post(`/checklists/${checklistId}/answer/${currentAnswer.id}`, {
                answer: currentAnswer.answer,
                note: currentAnswer.note
            });
        }
    }
    const onFinish = () => {
        Swal.fire({
            title: "Please add any comment about this checklist.",
            input: 'textarea',
            inputValue: checklist?.note,
            confirmButtonText: 'Finish',
            showCancelButton: true,
            customClass: {
                confirmButton: '!bg-sagreen',
                cancelButton: '!bg-sared',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`/checklists/${checklistId}`, {
                    note: result.value
                }).then((response) => {
                    if (response.data) {
                        Swal.fire({
                            title: 'Thank you for filling checklist.',
                            icon: 'success',
                            customClass: {
                                confirmButton: '!bg-sagreen',
                            }
                        });
                        navigate('/home');
                    }
                })
            }
        })
    }
    let title = checklist?.title ?? "CheckList";
    return (
        <div className="flex min-h-full flex-col px-4 lg:px-8 ">
            <div className="page-center">
                <PageHeader title={title} />
                <UnitHeader unitData={checklist?.unit} />
                <div className="page-body h-question relative mt-[110px]  mb-[2.5px] pt-4">
                    <div className='question-head text-sared text-left text-[15px]'>
                        Question {(currentAnswerIndex ?? 0) + 1} / {totalAnswers && totalAnswers > 0 ? totalAnswers : 1}
                    </div>
                    <div className='question-text text-sablack text-left text-[16px]'>
                        {currentAnswer?.question}
                    </div>
                    <div className='question-checklist mt-4'>
                        {
                            currentAnswer ?
                                <AnswerForm
                                    type={currentAnswer.type}
                                    answer={currentAnswer.answer}
                                    onChange={saveAnswer}
                                /> : <></>
                        }
                    </div>
                    <div className='mt-3'>
                        <Label>
                            <span className='text-sablack text-[15px]'>Note</span>
                        </Label>
                        <Textarea onChange={(event) => {
                            saveAnswer(undefined, event.target.value);
                        }} value={currentAnswer?.note ?? ''}
                            rows={3}
                            className='border-2 border-opacity-35 border-sablack text-sablack px-3 py-2'
                        />
                    </div>
                    <div className='question-bottom flex item-center space-between mt-2 w-full'>
                        <div className='question-left'>
                            {
                                currentAnswerIndex > 0 ?
                                    <Button onClick={
                                        () => {
                                            if (checklist && currentAnswerIndex > 0) {
                                                setCurrentAnswerIndex(currentAnswerIndex - 1);
                                                setCurrentAnswer(checklist.answers[currentAnswerIndex - 1]);
                                            }
                                        }
                                    } className='border-2 border-sared h-[40px] px-[5px] rounded-none'>
                                        <div className='flex items-center justify-center'>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.5" viewBox="0 0 23.5 23.5">
                                                    <g id="_15927388091543238858" data-name="15927388091543238858" transform="translate(0.75 0.75)">
                                                        <circle id="Ellipse_6" data-name="Ellipse 6" cx="11" cy="11" r="11" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Path_31" data-name="Path 31" d="M12.253,8,8,12.253l4.253,4.253" transform="translate(-1.62 -1.62)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Line_2" data-name="Line 2" x1="8" transform="translate(7 11)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className='text-sared  ml-2 text-[15px]'>
                                                Prev
                                            </div>
                                        </div>
                                    </Button> : <></>
                            }
                        </div>
                        <div className='question-right ml-auto'>
                            {
                                currentAnswerIndex !== (checklist?.answers?.length ?? 0) - 1 ?
                                    <Button onClick={
                                        () => {
                                            if (checklist) {
                                                setCurrentAnswerIndex(currentAnswerIndex + 1);
                                                setCurrentAnswer(checklist.answers[currentAnswerIndex + 1]);
                                            }
                                        }
                                    } className='border-2 border-sared bg-sared h-[40px] px-[5px] rounded-none'>
                                        <div className='flex items-center justify-center'>
                                            <div className='text-sawhite  mr-2 text-[15px]'>
                                                Next
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.5" viewBox="0 0 23.5 23.5">
                                                    <g id="_15927388091543238858" data-name="15927388091543238858" transform="translate(0.75 0.75)">
                                                        <circle id="Ellipse_6" data-name="Ellipse 6" cx="11" cy="11" r="11" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Path_31" data-name="Path 31" d="M8,8l4.253,4.253L8,16.507" transform="translate(3.367 -1.62)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Line_2" data-name="Line 2" x2="8" transform="translate(7 11)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </g>
                                                </svg>

                                            </div>
                                        </div>
                                    </Button>
                                    :
                                    <Button onClick={onFinish} className='border-2 border-sagreen bg-sagreen h-[40px] px-[5px] rounded-none'>
                                        <div className='flex items-center justify-center'>
                                            <div className='text-sawhite  mr-2 text-[15px]'>
                                                Finish
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>

                                            </div>
                                        </div>
                                    </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChecklistPage;