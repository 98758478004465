import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider, { useAuth } from "./hooks/AuthProvider";
import MainLayout from "./layout/MainLayout";
import Home from "./pages/Home";
import SplashScreen from "./pages/SplashScreen";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import axios from 'axios';
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";
import UnitDetail from "./pages/UnitDetail";
import { StrictMode } from "react";
import ChecklistPage from "./pages/ChecklistPage";
import UnderConstruction from "./pages/UnderConstruction";
import Swal from "sweetalert2";
import StockCheckPage from "./pages/StockCheckPage";

function App() {

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.response.use(
    response => response,
    error => {
      if(error?.response?.status === 401){
        localStorage.removeItem("token");
      }
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error'
      });
      return error;
    });

  const user: any = useAuth();
  if (user?.token) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${user.token}`,
    }
  }


  return (
    <StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<SplashScreen />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/unit/:unitId" element={<UnitDetail />} />
              <Route path="/unit/:unitId/:tab" element={<UnitDetail />} />
              <Route path="/checklist/:checklistId" element={<ChecklistPage />} />
              <Route path="/checklist/:checklistId/question/:questionId" element={<ChecklistPage />} />
              <Route path="/stock-check/:checklistId" element={<StockCheckPage />} />
              <Route path="/settings" element={<UnderConstruction />} />
              <Route path="/notifications" element={<UnderConstruction />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </StrictMode>
  );
}

export default App;
