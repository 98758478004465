import { Outlet } from "react-router-dom";


const MainLayout = () => {
    return (
        <section className="h-dvh">
            <Outlet />
        </section >
    )
};

export default MainLayout;