import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token) return <Navigate to="/login" />;
  return <div className="h-dvh overflow-hidden">
            <Outlet />
          
        </div >
};

export default PrivateRoute;