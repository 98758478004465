import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/AuthProvider";
import PageHeader from "../components/PageHeader";
import { Label, TextInput } from "flowbite-react";
import * as yup from 'yup'
import useYupValidationResolver from '../hooks/YupValidationResolver'
import { useForm } from 'react-hook-form'
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

function Profile() {
    const auth = useAuth()
    const [t] = useTranslation('common');

    const [error, setError] = useState<string | undefined>(undefined)
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(
        auth.user?.profile_photo?.filePath ?
            process.env.REACT_APP_API_BASE_URL + '/uploads' + auth.user?.profile_photo?.filePath :
            '/assets/img/user.png'
    )
    const profileSchema = yup.object({
        name: yup.string().trim().nonNullable().required('Please enter a valid name'),
        email: yup.string().email('Email must be a valid email').required('Email is required'),
        password: yup
            .string()
            .test(
                'empty-or-6-characters-check',
                'Password must be at least 6 characters',
                password => !password || password.length >= 6,
            ),
        password_again: yup
            .string()
            .oneOf([
                yup.ref('password')
            ], 'Passwords must match')
            .optional(),
    })

    const resolver = useYupValidationResolver(profileSchema)


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver, defaultValues: useMemo(() => {
            return {
                name: auth.user?.name,
                email: auth.user?.email
            };
        }, [auth])
    })

    useEffect(() => {
        reset({
            name: auth.user?.name,
            email: auth.user?.email,
            profile_photo: auth.user?.profile_photo?.id
        });
        setProfilePhotoUrl(
            auth.user?.profile_photo?.filePath ?
                process.env.REACT_APP_FILE_STORAGE_URL + auth.user?.profile_photo?.filePath :
                '/assets/img/user.png'
        )
    }, [auth.user, reset]);

    const onFileChange = async (event: ChangeEvent) => {
        let target = event.target as any
        if (target.files.length > 0) {
            for (let file of target.files) {
                var formData = new FormData()
                formData.append('file', file)
                formData.append('validate', 'image')
                formData.append('name', 'users[profile_photo]')
                let uploadApi = '/files/upload'
                await axios
                    .post(uploadApi, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                    .then((response) => {
                        if (response?.data) {
                            setProfilePhotoUrl(
                                process.env.REACT_APP_FILE_STORAGE_URL + response.data.filePath
                            )
                            setValue('profile_photo', response.data.id);
                        }
                    })
            }
        }
    }

    const onSubmit = async (data: any) => {
        try {
            setError(undefined)
            let requestData: any = {
                name: data.name,
                email: data.email,
                profile_photo: data.profile_photo
            };
            if (data.password) {
                requestData.password = data.password;
                requestData.password_again = data.password_again;
            }
            axios.put('/profile', requestData)
                .then((response) => {
                    auth.fetchMeData()
                    Swal.fire({
                        title: 'Success',
                        text: 'Saved successfully',
                        icon: 'success'
                    });
                }).catch((reason) => {
                    Swal.fire({
                        title: 'Error',
                        text: reason.response.data.message,
                        icon: 'error'
                    });
                })
        } catch (error: any) {
            setError(error.message)
        }
    }
    return (
        <div className="h-dvh overflow-hidden">
            <div className="page-center">
                <div className="w-full px-3 pb-[220px]">
                    <PageHeader title={auth.user?.name ?? 'Profile'} />
                    <div className="page-body h-question relative mt-[60px] overflow-x-scroll  mb-[2.5px] pt-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Label className="flex justify-center">
                                <div className="relative">
                                    <span className="text-sawhite absolute end-0 bg-sared rounded-full flex justify-center items-center p-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                                    </span>
                                    <div className="w-[100px] h-[100px] rounded-full flex justify-center items-center">
                                        <img src={profilePhotoUrl} className="w-[100px] h-[100px] rounded-full object-cover" alt={auth.user?.name} />
                                    </div>

                                </div>
                                <input type="file" className="hidden" onChange={onFileChange} accept="image/png,image/jpg,image/jpeg" />
                                <input {...register('profile_photo')} type="hidden" />
                            </Label>
                            {error ? <div className='flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 '>
                                {error}
                            </div> : <></>}
                            <fieldset className="flex flex-col gap-3 mb-4">
                                <Label className="leading-[.5rem]">
                                    <span className='text-sablack text-[14px]'>Name</span>
                                </Label>
                                <TextInput className="text-sablack" {...register('name')} />
                                {errors.name && errors.name.message && <div className="flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 mt-1">{errors.name.message as string}</div>}
                            </fieldset>
                            <fieldset className="flex flex-col gap-3 mb-4">
                                <Label className="leading-[.5rem]">
                                    <span className='text-sablack text-[14px]'>Email</span>
                                </Label>
                                <TextInput className="text-sablack" {...register('email')} />
                                {errors.email && errors.email.message && <div className="flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 mt-1">{errors.email.message as string}</div>}
                            </fieldset>
                            <fieldset className="flex flex-col gap-3 mb-4">
                                <Label className="leading-[.5rem]">
                                    <span className='text-sablack text-[14px]'>Password</span>
                                </Label>
                                <TextInput type="password" autoComplete="new-password" className="text-sablack" {...register('password')} />
                                {errors.password && errors.password.message && <div className="flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 mt-1">{errors.password.message as string}</div>}
                            </fieldset>
                            <fieldset className="flex flex-col gap-3 mb-4">
                                <Label className="leading-[.5rem]">
                                    <span className='text-sablack text-[14px]'>Password Again</span>
                                </Label>
                                <TextInput type="password" autoComplete="new-password" className="text-sablack text-[13px]" {...register('password_again')} />
                                {errors.password_again && errors.password_again.message && <div className="flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 mt-1">{errors.password_again.message as string}</div>}
                            </fieldset>
                            <fieldset className="flex mb-4 ">
                            {/* <Button
                                    className='border-2 border-sared h-[40px] px-[5px] rounded-sm text-sared m-auto  w-[40%] text-[14px] mr-1 '
                                    onClick={auth.logout}>
                                    {t('Logout')}
                                </Button> */}
                                <input
                                    type="button"
                                    onClick={auth.logout}
                                    value={t("Logout")}
                                    className='border-2 border-sared h-[40px] px-[5px] text-sared bg-sawhite rounded-sm m-auto w-[40%] text-[14px] mr-1'
                                />
                        
                                <input
                                    type="submit"
                                    value={t("Save")}
                                    className='border-2 border-sagreen h-[40px] px-[5px] text-sawhite bg-sagreen rounded-sm m-auto w-[60%] text-[14px]'
                                />
                        
                            </fieldset>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Profile;