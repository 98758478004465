import React from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

interface PageHeaderProps {
    title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {

    return (
        <div className="page-header w-full fixed top-0 left-0">
            <DocumentTitle title={title} />
            <div className="back-bg flex items-center px-4 py-[10px] border-b border-[#D1D1D1]">
                <Link to="../home">
                    <div className="back-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <g id="back" transform="translate(1 1)">
                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="14" cy="14" r="14" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <g id="_3046788041543238896" data-name="3046788041543238896" transform="translate(4.827 5.141)">
                                    <path id="Path_32" data-name="Path 32" d="M3,6.8,9.174,2l6.174,4.8v7.545a1.372,1.372,0,0,1-1.372,1.372h-9.6A1.372,1.372,0,0,1,3,14.347Z" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_33" data-name="Path 33" d="M9,18.859V12h4.116v6.859" transform="translate(-1.884 -3.141)" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </Link>
                <div className="back-title ml-auto mr-auto">
                    <h1 className="page-title text-sablack text-[14px] ">{title}</h1>
                </div>
                <Link to="../profile">
                    <div className="back-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                            <g id="back" transform="translate(1 1)">
                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="14" cy="14" r="14" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <g id="_6391520041543238940" data-name="6391520041543238940" transform="translate(4.128 4.394)">
                                    <path id="Path_34" data-name="Path 34" d="M15.743,19.4V17.936A2.936,2.936,0,0,0,12.807,15H6.936A2.936,2.936,0,0,0,4,17.936V19.4" transform="translate(0 -3.193)" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.936" cy="2.936" r="2.936" transform="translate(6.936 3)" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </Link>
            </div>



        </div>
    );
};

export default PageHeader;