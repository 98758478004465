import { Label, Radio, TextInput } from "flowbite-react";

type AnswerFormProps = {
    type: string,
    answer: any,
    onChange: (answer: any) => void
}


export default function AnswerForm({
    type,
    answer,
    onChange
}: AnswerFormProps) {
    switch (type) {
        case "Yes - No":
            return <fieldset className="flex flex-col gap-3">
                <div className="flex items-center gap-2 relative">
                    <Radio className="question-radio opacity-0" id="yes-button" name="answer" checked={answer === true} onChange={() => onChange(true)} />
                    <Label className="question-label px-2 shadow-md rounded hover:cursor-pointer" htmlFor="yes-button">
                        <span className='text-sablack text-[15px]'>Yes</span>
                    </Label>
                </div>
                <div className="flex items-center gap-2 relative question-no">
                    <Radio className="question-radio opacity-0" id="germany" name="answer" checked={answer === false} onChange={() => onChange(false)} />
                    <Label className="question-label px-2 shadow-md rounded hover:cursor-pointer" htmlFor="germany"><span className="text-sablack text-[15px]">No</span></Label>
                </div>
            </fieldset>
        case "Short Text":
            return <fieldset className="flex flex-col gap-3">
                <Label>
                    <span className='text-sablack text-[15px]'>Answer</span>
                </Label>
                <TextInput className="text-sablack" value={answer} onChange={(event) => onChange(event.target.value)}/>
            </fieldset>
        default:
            return <></>
    }
}