import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import PageHeader from '../components/PageHeader';
import UnitHeader from '../components/UnitHeader';
import { StockCheck, StockQuantity } from '../components/UnitStockCheckList';
import { Button, Table, TextInput } from 'flowbite-react';
import Swal from 'sweetalert2';

const StockCheckPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const checklistId = params.checklistId;

    const [stockCheck, setStockCheck] = useState<StockCheck | null>(null);

    // Fetch Stock Check
    useEffect(() => {
        const fetchStockCheck = async () => {
            try {
                const response = await axios.get(`/stock_checks/${checklistId}`);
                setStockCheck(response.data);
            } catch (err) {
                console.log("Check Error", err);
            }
        };
        if (checklistId) {
            fetchStockCheck();
        }
    }, [checklistId]);

    const saveQuantity = (stockQuantity: StockQuantity, quantity: number) => {
        axios.patch(
            `stock_checks/${stockCheck?.id}/quantities/${stockQuantity.id}`, 
            {quantity}, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }
        );
    }

    let title = stockCheck ? `${stockCheck.supplier.name}` : '';
    return (
        <div className="flex min-h-full flex-col px-4 lg:px-8 ">
            <div className="page-center">
                <PageHeader title={title} />
                <UnitHeader unitData={stockCheck?.unit} tab='stock-check'/>
                <div className="page-body h-question relative mt-[110px]  mb-[2.5px] pt-4 overflow-auto">
                    <Table className='mb-64'>
                        <Table.Head>
                            <Table.HeadCell>Product</Table.HeadCell>
                            <Table.HeadCell>Stock</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {stockCheck?.stockQuantities.map((stockQuantity) => {
                                return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={stockQuantity.id}>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                        <span className='text-sared'>{stockQuantity.product.code}</span> <br></br>
                                        {stockQuantity.product.title}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white w-[180px]">
                                        <TextInput sizing="sm" type='number' step={0.01} onChange={(event) => {
                                              saveQuantity(stockQuantity, event.target.value ? parseFloat(event.target.value) : 0);
                                            }} defaultValue={stockQuantity?.quantity ?? 0}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>

                    <Button onClick={() => {
                        navigate(`/unit/${stockCheck?.unit.id}`)
                        Swal.fire(
                            'Success',
                            'Stock Check completed successfully',
                            'success'
                        );
                    }} className='border-2 rounded-full border-sagreen bg-sagreen fixed end-5 bottom-10'>
                        <div className='flex items-center justify-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                            </div>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default StockCheckPage;