import { forwardRef, useId } from 'react'

const InputField = forwardRef(({ label, error, ...inputProps }: any, ref) => {
  // to avoid any clashes with other inputs on the page
  const id = useId() + '-' + inputProps.name
  return (
    <div className='w-full'>
      <div className="relative text-left">
        <label htmlFor={id} className="text-xs text-sablack  origin-[0] bg-white peer-focus:text-blue-600 start-1">
          {label}
        </label>
        <input
          id={id}
          ref={ref}
          className="block h-[50px] px-2 py-2 mt-1 w-full text-xs text-sablack bg-transparent rounded-lg border-sablack border appearance-none dark:text-white  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          {...inputProps}
        />
      </div>
      {error && error.message && <div className="flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 mt-1">{error.message}</div>}
    </div>

  )
})

export default InputField
