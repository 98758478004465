import DocumentTitle from "react-document-title";
import { useTranslation } from "react-i18next";
import UnitList from "../components/UnitList";

export interface Todo {
    id: string;
    name: string;
    address: string;
    created_at: string;
}

function Home() {
    const [t] = useTranslation('common');


    return (
        <div className="">
            <DocumentTitle title={t("Welcome to", { app: process.env.REACT_APP_NAME })}></DocumentTitle>
            <div className="page-center">
                <div className="unit w-full text-center py-3">
                    <div className="unit-icon mx-auto max-w-[65px]">
                        <img className="max-w-full h-auto mx-auto" src="./assets/img/unit-icon.png" alt="Unit Icon" />
                    </div>
                    <div className="unit-text text-center">
                        <h3 className="mt-1 text-lg font-medium capitalize text-sablack">Select the Unit </h3>
                        <p className="mt-1 text-[13px] font-light text-sablack">Welcome to Savills Catering.</p>
                        <p className="text-[13px] font-light text-sablack">Select an unit to continue.</p>
                    </div>
                </div>
                <div className="unit-alt px-3">
                    <UnitList />
                </div>
            </div>
        </div>


    )
}

export default Home;