import React from "react";
import { Link } from 'react-router-dom';

export interface Unit {
    id: string;
    name: string;
    address: string;
}
interface UnitCardProps {
    unit: Unit;
}
const UnitCard: React.FC<UnitCardProps> = ({ unit }) => {
    return (
        <div className="unit-card bg-[#F5F5F5]  px-2 py-2 rounded-lg mt-2">
            <Link to={`/unit/${unit.id}`}>
                <div className="unit-card-head text-[13px] font-semibold text-sablack">
                    {unit.name}
                </div>
                <div className="unit-card-text unit-card-head text-[13px] font-normal text-sablack">
                    {unit.address}
                </div>
            </Link>
        </div >
    );
}



export default UnitCard;