import { useTranslation } from "react-i18next";
import DocumentTitle from 'react-document-title'
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import { useEffect } from "react";
function SplashScreen() {
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const auth = useAuth();
    useEffect(() => {
        if(auth.user?.id){
            navigate('/home');
        }
    }, [auth.user?.id, navigate])
    return (
       
        <div className="h-dvh overflow-hidden">
             <DocumentTitle title={t('Savills Catering')}></DocumentTitle>
            <div className="splash-bg grid h-full">
                <div className="splash-logo mt-auto max-w-[270px] mx-auto px-4">
                    <img className="max-w-full h-auto" src="./assets/img/logo.png" alt="Savills Logo"></img>
                </div>
                <div className="splash-alt relative w-full mt-auto">
                    <div className="splash-effect absolute top-[-1px] left-0 w-full ">
                        <img className="w-full max-h-[85px] object-fill" src="./assets/img/splash-effect.png" alt="Splash Effect"/>
                    </div>
                    <div className="splash-text bg-sared flex items-center justify-center py-8 pt-24">
                        <div className="w-full">
                            <div className="font-['Kalam'] text-2xl text-center">
                                “Small enough<br/>
                                to care large enough<br/>
                                to succeed"
                            </div>
                            <div className="mt-5">
                                <Link to="/login" className="bg-[#FFFFFF] flex items-center justify-center py-[14px] w-full text-sablack font-semibold text-[15px] rounded-lg max-w-[270px] mx-auto ">  
                                    {t("Sign in")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SplashScreen;