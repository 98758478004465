import React, { useState, useEffect } from "react";
import axios from 'axios';
import UnitCard from './UnitCard';
import { Pagination } from "flowbite-react";
import useDebounce from "../hooks/useDebounce";
interface Unit {
    id: string;
    name: string;
    address: string;
}

const UnitList: React.FC = () => {
    const [units, setUnits] = useState<Unit[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const debounceValue = useDebounce(searchTerm, 500);
    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await axios.get("/units", {
                    params: {
                        search: debounceValue,
                        page: currentPage
                    }
                });
                setUnits(response.data.data);
                setTotalPages(response.data.pageCount)
            } catch (err) {
                console.log(err);
            }
        };
        fetchUnits();
    }, [debounceValue, currentPage]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCurrentPage(1)
    };

    return (
        <div>
            <div className="">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Search"
                    className="h-[40px] text-[13px] bg-sawhite text-sablack border border-[#B1B1B1] w-full rounded-lg px-3 placeholder:text-[13px]  placeholder:font-normal placeholder:text-[#B1B1B1] focus-visible:border-sablack"
                />
            </div>
            <div className="unit-bg shadow-lg px-2 py-3 rounded-lg border border-[#F5F5F5] mt-3 h-home overflow-y-scroll">
                <div>
                    {units.map(unit => (
                        <UnitCard key={unit.id} unit={unit} />
                    ))}
                </div>
            </div>
            <div className="flex justify-center">
                {
                    totalPages > 1 ?
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page)
                            }}
                            className="pagination"
                            previousLabel="<"
                            nextLabel=">"
                        /> : <></>
                }
            </div>
        </div>

    );
}

export default UnitList;