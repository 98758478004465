import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Pagination } from 'flowbite-react';
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Label } from 'flowbite-react';
import moment from 'moment';

interface Checklist {
    id: number;
    title: string;
    createdAt: string;
}
interface Template {
    id: number;
    title: string;
}
function UnitChecklistList({ unitData }: { unitData: any }) {
    const [t] = useTranslation('common');
    const navigate = useNavigate();

    const [checklists, setChecklists] = useState<Checklist[] | []>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<number | ''>('');
    const [templates, setTemplates] = useState<Template[]>([]);

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTemplate(parseInt(event.target.value));
    };
    useEffect(() => {
        const fetchChecklist = async () => {
            try {
                const response = await axios.get(`/checklists`, {
                    params: {
                        unit: unitData.id,
                        page: currentPage
                    }
                });
                setChecklists(response.data.data);
                setTotalPages(response.data.pageCount)
            } catch (err) {
                console.log(err);
            }
        };
        if (unitData?.id) {
            fetchChecklist();
        }
    }, [unitData, currentPage]);
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axios.get<{ data: Template[] }>('/checklist/templates/');
                setTemplates(response.data.data);
            } catch (err) {
                console.log(err);
            }
        };
        if (openModal) {
            fetchTemplates();
        }
    }, [openModal]);
    const createChecklist = async () => {
        try {
            const response = await axios.post('/checklists', {
                template: selectedTemplate,
                unit: unitData.id,
            })
            if (response.data.checklist.id) {
                navigate(`/checklist/${response.data.checklist.id}`);
            }

        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <div className="unit-bg shadow-lg px-2 py-3 rounded-lg border border-[#F5F5F5] h-home overflow-y-scroll">
                {checklists.length > 0 ? (
                    checklists.map((checklist, index) => {
                        return <div className="unit-card bg-[#F5F5F5]  px-2 py-2 rounded-lg mt-2 relative" key={index}>
                            <Link to={`/checklist/${checklist.id}`} className='float-end after:absolute after:inset-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 border-2 rounded-full border-sared text-sared" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
                            </Link>
                            <div className="checklist-card-text flex items-center">
                                <div className="checklist-card-head text-[13px] font-normal text-sablack">
                                    Title:
                                </div>
                                <div className="checklist-card-text text-[13px] font-bold ml-2 text-sablack">
                                    {checklist.title}
                                </div>
                            </div>
                            <div className="checklist-card-text flex items-center">
                                <div className="checklist-card-head text-[13px] font-normal text-sablack">
                                    Created At:
                                </div>
                                <div className="checklist-card-text text-[13px] font-semilbold ml-2 text-sablack">
                                    {moment(checklist.createdAt).toDate().toLocaleString()}
                                </div>
                            </div>
                        </div>
                    })
                ) : (
                    <p className="text-md text-sablack">{t("There is no checklist")}</p>
                )}

            </div>
            <div className="flex justify-center">
                {
                    totalPages > 1 ?
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page)
                            }}
                            className="pagination"
                            previousLabel="<"
                            nextLabel=">"
                        /> : <></>
                }
            </div>
            <div>
                <button onClick={() => setOpenModal(true)} className="bg-sared px-3 py-2 text-[15px] w-full rounded-md mt-3">
                    Create Checklist
                </button>
            </div>
            <Modal className="bg-sablack" show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header className="bg-sawhite text-sablack py-3"><span className="text-sm">Create Checklist</span> </Modal.Header>
                <Modal.Body className="bg-sawhite">
                    <div className="space-y-6">
                        <div className="checklist-dropdown-bg">
                            <div className="">
                                <div className="mb-2 block">
                                    <Label className="text-sablack text-[15px]" htmlFor="countries" value="Select a template to create checklist" />
                                </div>
                                <select className="border border-sablack w-full h-[42px] text-sablack rounded-lg" value={selectedTemplate} onChange={handleSelectChange}>
                                    <option className="bg-sared" value="">
                                        <span className="text-sablack  text-[14px]">Select a template</span>
                                    </option>
                                    {templates.map(template => (
                                        <option className="bg-sared" key={template.id} value={template.id}>
                                            {template.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-sawhite" >
                    <Button className="bg-[#F5F5F5] text-sablack  border-0 py-1 h-[42px]" onClick={() => setOpenModal(false)}>
                        <span className="text-[14px]"> Close</span>
                    </Button>

                    <Button className="bg-sared w-full h-[42px]" onClick={() => {
                        createChecklist();
                        setOpenModal(false);
                    }} >
                        <span className="text-[14px]"> Create Checklist</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UnitChecklistList;