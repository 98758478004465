import * as yup from 'yup'
import useYupValidationResolver from '../hooks/YupValidationResolver'
import { useForm } from 'react-hook-form'
import InputField from '../components/form-elements/InputField'
import { useAuth } from '../hooks/AuthProvider'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DocumentTitle from 'react-document-title'

export default function ForgetPassword() {


    const [error, setError] = useState<string | undefined>(undefined)
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if(auth.user?.id){
            navigate('/home');
        }
    }, [auth.user?.id, navigate])

    const inputSchema = {
        email: yup.string().email('Email must be a valid email').required('Email is required'),
    }

    const forgotPasswordSchema = yup.object({
        email: inputSchema.email,
    })

    const resolver = useYupValidationResolver(forgotPasswordSchema)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver })

    const onSubmit = async (data: any) => {
        try {
            setError(undefined)
            await auth.forgetPassword(data.email);
        } catch (error: any) {
            setError(error.message)
        }
    }
    const [t] = useTranslation('common');
    return <div className="flex min-h-full flex-col justify-center items-center px-4 lg:px-8 h-dvh">
        <DocumentTitle title={t('Forgot Password')}></DocumentTitle>
        <div className="login grid h-full w-full items-center">
            <div className="logo-header text-center w-full">
                <div className="logo max-w-[210px] mx-auto">
                    <img className="max-w-full h-auto" src="./assets/img/logo.png" alt='Savills Logo'/>
                </div>
                <div className="text text-center">
                    <h2 className="mt-8 text-2xl font-medium capitalize text-sablack">
                        {t("Forgot Password")}
                    </h2>
                </div>
                <div className="form mt-4">
                    <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6 max-w-[450px] mx-auto">
                        {error ? <div className='flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 '>
                            {error}
                        </div> : <></>}
                        <InputField
                            label={t("Email Address")}
                            type="email"
                            placeholder="expamle@email.com"
                            autoComplete={'current-email'}
                            error={errors.email}
                            {...register('email')}
                        />



                        <input
                            type="submit"
                            value={t("Reset Password")}
                            className="flex w-full justify-center rounded-lg px-3  h-[50px] text-sm font-semibold leading-6 text-white shadow-sm bg-sared hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        />

                        <Link to={"/login"} type='submit' className='w-full text-center  block text-sared text-xs font-medium underline-offset-1 cursor-pointer mx-auto'>
                            {t("Login")}
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    </div>

}