import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Pagination, Label } from 'flowbite-react';
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { User } from '../hooks/AuthProvider';
import { Unit } from './UnitCard';

export interface StockCheck {
    id: number;
    unit: Unit,
    supplier: Supplier,
    stockQuantities: StockQuantity[],
    createdBy: User,
    createdAt: string;
}
export interface Supplier {
    id: number;
    name: string;
}

export interface StockQuantity {
    id: number;
    quantity: string;
    product: Product;
}

export interface Product {
    id: number;
    code: string;
    title: string;
}

function UnitStockCheckList({ unitData }: { unitData: any }) {
    const [t] = useTranslation('common');
    const navigate = useNavigate();

    const [stockChecks, setStockChecks] = useState<StockCheck[] | []>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<number | ''>('');
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSupplier(parseInt(event.target.value));
    };
    useEffect(() => {
        const fetchStockChecks = async () => {
            try {
                const response = await axios.get(`/units/${unitData.id}/stock_checks`, {
                    params: {
                        unit: unitData.id,
                        page: currentPage
                    }
                });
                setStockChecks(response.data["hydra:member"]);
                setTotalPages(parseInt(response.data['hydra:totalItems'] / 10 as any))
            } catch (err) {
                console.log(err);
            }
        };
        if (unitData?.id) {
            fetchStockChecks();
        }
    }, [unitData, currentPage]);
    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get('/suppliers');
                setSuppliers(response.data["hydra:member"] as any);
            } catch (err) {
                console.log(err);
            }
        };
        if (openModal) {
            fetchSuppliers();
        }
    }, [openModal]);
    const createStockCheck = async () => {
        try {
            const response = await axios.post('/stock_checks', {
                supplierId: selectedSupplier,
                unitId: unitData.id,
            })
            if (response.data.stockcheck.id) {
                navigate(`/stock-check/${response.data.stockcheck.id}`);
            }

        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <div className="unit-bg shadow-lg px-2 py-3 rounded-lg border border-[#F5F5F5] h-home overflow-y-scroll">
                {stockChecks.length > 0 ? (
                    stockChecks.map((stockCheck, index) => {
                        return <div className="unit-card bg-[#F5F5F5]  px-2 py-2 rounded-lg mt-2 relative" key={index}>
                            <Link to={`/stock-check/${stockCheck.id}`} className='float-end after:absolute after:inset-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 border-2 rounded-full border-sared text-sared" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
                            </Link>
                            <div className="checklist-card-text flex items-center">
                                <div className="checklist-card-head text-[13px] font-normal text-sablack">
                                    Supplier:
                                </div>
                                <div className="checklist-card-text text-[13px] font-bold ml-2 text-sablack">
                                    {stockCheck.supplier.name}
                                </div>
                            </div>
                            <div className="checklist-card-text flex items-center">
                                <div className="checklist-card-head text-[13px] font-normal text-sablack">
                                    Created At:
                                </div>
                                <div className="checklist-card-text text-[13px] font-semilbold ml-2 text-sablack">
                                    {moment(stockCheck.createdAt).toDate().toLocaleString()}
                                </div>
                            </div>
                        </div>
                    })
                ) : (
                    <p className="text-md text-sablack">{t("There is no checklist")}</p>
                )}

            </div>
            <div className="flex justify-center">
                {
                    totalPages > 1 ?
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page)
                            }}
                            className="pagination"
                            previousLabel="<"
                            nextLabel=">"
                        /> : <></>
                }
            </div>
            <div>
                <button onClick={() => setOpenModal(true)} className="bg-sared px-3 py-2 text-[15px] w-full rounded-md mt-3">
                    New Stock Check
                </button>
            </div>
            <Modal className="bg-sablack" show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header className="bg-sawhite text-sablack py-3"><span className="text-sm">New Stock Check</span> </Modal.Header>
                <Modal.Body className="bg-sawhite">
                    <div className="space-y-6">
                        <div className="checklist-dropdown-bg">
                            <div className="">
                                <div className="mb-2 block">
                                    <Label className="text-sablack text-[15px]" htmlFor="countries" value="Select a supplier to create stock check" />
                                </div>
                                <select className="border border-sablack w-full h-[42px] text-sablack rounded-lg" value={selectedSupplier} onChange={handleSelectChange}>
                                    <option className="bg-sared" value="">
                                        <span className="text-sablack  text-[14px]">Select a supplier</span>
                                    </option>
                                    {suppliers.map(supplier => (
                                        <option className="bg-sared" key={supplier.id} value={supplier.id}>
                                            {supplier.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-sawhite" >
                    <Button className="bg-[#F5F5F5] text-sablack  border-0 py-1 h-[42px]" onClick={() => setOpenModal(false)}>
                        <span className="text-[14px]"> Close</span>
                    </Button>

                    <Button className="bg-sared w-full h-[42px]" onClick={() => {
                        createStockCheck();
                        setOpenModal(false);
                    }} >
                        <span className="text-[14px]"> Create Stock Check</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UnitStockCheckList;