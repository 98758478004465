import * as yup from 'yup'
import useYupValidationResolver from '../hooks/YupValidationResolver'
import { useForm } from 'react-hook-form'
import InputField from '../components/form-elements/InputField'
import { useAuth } from '../hooks/AuthProvider'
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import Swal from 'sweetalert2'

export default function ResetPassword() {

    const [resetToken, setResetToken] = useState<string>("");
    const [error, setError] = useState<string | undefined>(undefined)
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if(auth.user?.id){
            navigate('/home');
        }
    }, [auth.user?.id, navigate])

    const inputSchema = {
        code: yup.string().required('Code is required'),
        password: yup
            .string()
            .min(6, 'Password must have at least 6 characters')
            .required('Password is required'),
        password_again: yup
            .string()
            .required('Password Again is required')
            .oneOf([
                yup.ref('password')
            ], 'Passwords must match')
    };

    const verifyTokenSchema = yup.object({
        code: inputSchema.code
    })

    const resetPasswordSchema = yup.object({
        password: inputSchema.password,
        password_again: inputSchema.password_again
    })

    const verifyTokenResolver = useYupValidationResolver(verifyTokenSchema)
    const resetPasswordResolver = useYupValidationResolver(resetPasswordSchema)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: resetToken ? resetPasswordResolver : verifyTokenResolver })

    const onSubmit = async (data: any) => {
        try {
            setError(undefined)
            if (!resetToken) {
                auth.verifyForgetPasswordOtp(
                    data.code
                ).then((response) => {
                    reset()
                    setResetToken(response.data.reset_token)
                }).catch((error) => {
                    setError(error.response.data.message)
                })
            } else {
                auth.resetPassword(
                    resetToken,
                    data.password
                );
            }
        } catch (error: any) {
            setError(error.message)
        }
    }

    const resendMail = async () => {
        try {
            setError(undefined)
            await auth.forgetPassword(
                localStorage.getItem("forget-password-username") as string
            );
            Swal.fire({
                title: t('Success'),
                text: t('Email resent successfully.'),
                icon: "success"
            });
        } catch (error: any) {
            setError(error.message)
        }
    }

    const [t] = useTranslation('common');
    return <div className="flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
        <DocumentTitle title={t('Reset Password')}></DocumentTitle>
        <div className="login grid h-full w-full items-center">
            <div className="logo-header text-center w-full">
                <div className="logo max-w-[210px] mx-auto">
                    <img className="max-w-full h-auto" src="./assets/img/logo.png" alt='Savills Logo'/>
                </div>
                <div className="text text-center">
                    <h2 className="mt-8 text-2xl font-medium capitalize text-sablack">
                    {t("Reset Password")}
                    </h2>
                </div>
                <div className="form mt-4">
                    <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6 max-w-[450px] mx-auto">
                    {
                    error ?
                        <div className='flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2'>
                            {error}
                        </div> :
                        (
                            resetToken ?
                                <></> :
                                <div className='flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2'>
                                    {t("If your email is registered in our system, you’ll receive an email in your inbox.")}
                                </div>
                        )}
                {
                    resetToken ?
                        <>
                            <InputField
                                label={t("Password")}
                                type="password"
                                autoComplete={'new-password'}
                                error={errors.password}
                                {...register('password')}
                            />
                            <InputField
                                label={t("Password Again")}
                                type="password"
                                autoComplete={'new-password'}
                                error={errors.password_again}
                                {...register('password_again')}
                            />
                            <input
                                type="submit"
                                value={t("Reset Password")}
                                className="hover:cursor-pointer flex w-full justify-center rounded-lg px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm bg-sared hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            />
                        </> :
                        <>
                            <InputField
                                label={t("OTP code")}
                                type="number"
                                autoComplete={'off'}
                                error={errors.code}
                                {...register('code')}
                            />

                            <input
                                type="submit"
                                value={"Verify Code"}
                                className="hover:cursor-pointer flex w-full justify-center rounded-lg px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm bg-sared hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            />
                        </>
                }

                {
                    resetToken ?
                        <></> :
                        <input
                            type="button"
                            value={t("Resend Mail")}
                            className="flex w-full justify-center rounded-lg px-3  h-[50px] text-sm font-semibold leading-6 text-white shadow-sm bg-sared hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={resendMail}
                        />
                }
                <Link to={"/login"} type='submit' className='w-full text-center  block text-sared text-xs font-medium underline-offset-1 cursor-pointer mx-auto'>
                    {t("Login")}
                </Link>
                    </form>
                </div>
            </div>
        </div>
    </div>

}