import * as yup from 'yup'
import useYupValidationResolver from '../hooks/YupValidationResolver'
import { useForm } from 'react-hook-form'
import InputField from '../components/form-elements/InputField'
import { useAuth } from '../hooks/AuthProvider'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DocumentTitle from 'react-document-title'
import Swal from 'sweetalert2'


export default function Login() {

    const [error, setError] = useState<string | undefined>(undefined)
    const auth = useAuth();

    const navigate = useNavigate();
    useEffect(() => {
        if(auth.user?.id){
            navigate('/home');
        }
    }, [auth.user?.id, navigate])

    const inputSchema = {
        email: yup.string().email('Email must be a valid email').required('Email is required'),
        password: yup
            .string()
            .required('Password is required'),
    }

    const loginSchema = yup.object({
        email: inputSchema.email,
        password: inputSchema.password,
    })

    const resolver = useYupValidationResolver(loginSchema)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver })

    const onSubmit = async (data: any) => {
        try {
            Swal.showLoading();
            setError(undefined)
            await auth.login(data.email, data.password);
            Swal.close();
        } catch (error: any) {
            setError(error.message)
        }
    }
    const [t] = useTranslation('common');
    return <div className="flex  flex-col justify-center items-center px-4 lg:px-8 h-dvh  overflow-hidden">
        <DocumentTitle title={t('Login')}></DocumentTitle>
        <div className="login grid h-dvh w-full items-center">
            <div className="logo-header text-center w-full">
                <div className="logo max-w-[210px] mx-auto">
                    <img className="max-w-full h-auto" src="./assets/img/logo.png" alt='Savills Logo'/>
                </div>
                <div className="text text-center ">
                    <h2 className="mt-8 text-2xl font-medium capitalize text-sablack">
                        Sign in to your <br />account
                    </h2>
                </div>
                <div className="form mt-4 mx-auto">
                    <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6  max-w-[450px] mx-auto">
                        {error ? <div className='flex items-start bg-salightred  text-sared text-xs text-start border rounded-md border-sared px-3 py-2 '>
                            {error}
                        </div> : <></>}
                        <InputField
                            label={t("Email Address")}
                            type="email"
                            placeholder="expamle@email.com"
                            autoComplete={'current-email'}
                            error={errors.email}
                            {...register('email')}
                        />

                        <InputField
                            label={t("Password")}
                            type="password"
                            placeholder="*************"
                            autoComplete={'current-password'}
                            error={errors.password}
                            {...register('password')}
                        />
                        <Link to="/forgot-password" className='w-full text-right  block text-sared text-xs font-medium underline-offset-1 cursor-pointer mx-auto'>
                            {t("Forgot Password")}
                        </Link>
                        <input
                            type="submit"
                            value={t("Login")}
                            className="hover:cursor-pointer flex w-full justify-center rounded-lg px-3  h-[50px] text-sm font-semibold leading-6 text-white shadow-sm bg-sared hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        />
                    </form>

                </div>
            </div>
        </div>
    </div>
}