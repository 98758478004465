import { useTranslation } from "react-i18next";
import PageHeader from "../components/PageHeader";
import { Link } from "react-router-dom";

function UnderConstruction() {
    const [t] = useTranslation('common');

    return (
        <div className="w-full text-center">
            <PageHeader title={'Page Under Construction'} />
            <div className="splash-bg grid h-full mt-[59px]">
                <div className="splash-logo mt-auto max-w-[270px] mx-auto px-4">
                    <img className="max-w-full h-auto" src="./assets/img/logo.png" alt="Savills Logo"></img>
                </div>
                <div className="splash-text bg-sared flex items-center justify-center py-8 pt-5 mt-20">
                        <div className="w-full">
                            <div className="font-['Kalam'] text-2xl text-center">
                                Page under Construction<br/>
                                Will be ready soon<br/>
                            </div>
                            <div className="mt-5">
                                <Link to="/home" className="bg-[#FFFFFF] flex items-center justify-center py-[14px] w-full text-sablack font-semibold text-[15px] rounded-lg max-w-[270px] mx-auto ">  
                                    <span className="text-2xl me-3">&#x2039;</span>
                                    <span className="text-xl">{t("Go to homepage")}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

    )
}

export default UnderConstruction;