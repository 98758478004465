import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from "../components/PageHeader";
import { useEffect, useState } from "react";
import axios from "axios";
import UnitHeader from '../components/UnitHeader';
import UnitChecklistList from '../components/UnitChecklistList';
import { Tabs } from 'flowbite-react';
import tabsTheme from '../components/tabsTheme';
import UnitStockCheckList from '../components/UnitStockCheckList';

function UnitDetail() {
    const params = useParams()
    const navigate = useNavigate();

    const [unitData, setUnitData] = useState<any>(null);
    let tab = 0;
    switch(params?.tab){
        case 'stock-check':
            tab = 1;
            break;
    }
    const [activeTab, setActiveTab] = useState(tab);
    useEffect(() => {
        const fetchUnitData = async () => {
            try {
                const response = await axios.get(`/units/${params.unitId}`);
                setUnitData(response.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchUnitData();
    }, [params.unitId]);

    useEffect(() => {
        if(unitData){
            let parameter = 'checklist';
            switch(activeTab){
                case 1:
                    parameter = 'stock-check';
            }
            navigate(`/unit/${unitData?.id}/${parameter}`);
        }
    // eslint-disable-next-line
    }, [activeTab])
    return (
        <div className="page-center p-5">
            <PageHeader title="Unit Detail" />
            <UnitHeader unitData={unitData} />
            <div className="page-body mt-[103px]">
                <Tabs aria-label="Unit Operations" theme={tabsTheme} onActiveTabChange={(tab) => setActiveTab(tab)}>
                    <Tabs.Item active={activeTab === 0} title="Checklists">
                        <UnitChecklistList unitData={unitData} />
                    </Tabs.Item>
                    <Tabs.Item active={activeTab === 1} title="Stock Checks">
                        <UnitStockCheckList unitData={unitData} />
                    </Tabs.Item>
                </Tabs>
            </div>
        </div>
    )
}

export default UnitDetail;