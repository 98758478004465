import { Link } from "react-router-dom";

export default function UnitHeader({ unitData, tab = "checklist" }: { unitData: any, tab?: string }) {
    return <div className="page-header w-full fixed top-[52px] left-0">
        <div className="unitcard-bg flex items-center px-4 py-[10px] shadow-md">
            <Link to={`/unit/${unitData?.id}/${tab}`} className="unitcard-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 46 46">
                    <g id="Group_65" data-name="Group 65" transform="translate(0.098 0.203)">
                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(-0.098 -0.203)" fill="#fff" stroke="#c4000a" strokeWidth="1.5">
                            <circle cx="23" cy="23" r="23" stroke="none" />
                            <circle cx="23" cy="23" r="22.25" fill="none" />
                        </g>
                        <g id="map-2" transform="translate(9.432 9.33)">
                            <path id="Path_26" data-name="Path 26" d="M0,0H26.791V26.791H0Z" fill="none" />
                            <path id="Path_27" data-name="Path 27" d="M13.047,20.186,9.7,18.512,3,21.861V7.349L9.7,4l6.7,3.349L23.093,4v8.372" transform="translate(0.349 0.465)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <path id="Path_28" data-name="Path 28" d="M9,4V18.512" transform="translate(1.047 0.465)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <path id="Path_29" data-name="Path 29" d="M15,7v6.14" transform="translate(1.744 0.814)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <path id="Path_30" data-name="Path 30" d="M21.717,20.717a3.349,3.349,0,1,0-4.735,0q.7.7,2.368,2.1Q21.109,21.324,21.717,20.717Z" transform="translate(1.861 1.744)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <path id="Path_31" data-name="Path 31" d="M19,18v.011" transform="translate(2.21 2.093)" fill="none" stroke="#c4000a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        </g>
                    </g>
                </svg>
            </Link>
            <div className="unitcard-text w-full ml-2 text-left">
                <div>
                    {unitData ? (
                        <>
                            <div className="text-[13px] font-semibold text-sablack">{unitData.name}</div>
                            <div className="text-[12px] font-normal text-sablack line-clamp-1 mt-[-2px]">{unitData.address}</div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    </div>
}